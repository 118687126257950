import { Alert } from 'components'
import { shareRef, shareBusinessProfile } from 'helpers'
export const handleShare = async (userRefId, userName) => {
  if (!userRefId || !userName) {
    Alert.error('Unable to share. Missing user information.')
    return
  }
  const shareData = shareRef(userRefId, userName)
  const textToShare = `${shareData.text}\n\n`
  // Check if running in Android WebView
  if (window.Android && window.Android.share) {
    // Use Android's native sharing
    window.Android.share(textToShare, shareData.title)
  } else {
    // Try Web Share API as fallback
    try {
      await navigator.share({
        title: shareData.title,
        text: textToShare
      })
    } catch (err) {
      console.error('Share failed:', err)
      // Fallback to WhatsApp if both native and Web Share API fail
      const whatsappText = `${shareData.text}\n\n`
      window.open(`https://wa.me/?text=${encodeURIComponent(whatsappText)}`, '_blank')
    }
  }
}
export const handleBusinessProfileShare = async (businessName, location) => {
  if (!businessName || !location) {
    Alert.error('Unable to share. Missing business information.')
    return
  }
  const shareData = shareBusinessProfile(businessName, location)
  const textToShare = `${shareData.text}\n\n${shareData.url}`
  // Check if running in Android WebView
  if (window.Android && window.Android.share) {
    // Use Android's native sharing
    window.Android.share(textToShare, shareData.title)
  } else {
    // Try Web Share API as fallback
    try {
      await navigator.share({
        title: shareData.title,
        text: textToShare
      })
    } catch (err) {
      console.error('Share failed:', err)
      // Fallback to WhatsApp if both native and Web Share API fail
      const whatsappText = `${shareData.text}\n\n${shareData.url}`
      window.open(`https://wa.me/?text=${encodeURIComponent(whatsappText)}`, '_blank')
    }
  }
}
