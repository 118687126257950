const shareBusinessProfile = (businessName, location) => {
  const shareUrl = window.location.href
  const shareText = `Check out ${businessName} in ${location} on BCN!\n\n  Find more Brahmin businesses and services on BCN.`

  return {
    title: 'BCN Business Profile',
    text: shareText,
    url: shareUrl
  }
}
export default shareBusinessProfile
